import React from "react";
import "./Spinner.css";

const Spinner = (props) => {
    return (
        <div className="loading_bg">
            <div className={"loader"}>Loading...</div>
        </div>);
};

export default Spinner;