import React, { Component } from "react";
import axios from "axios";
import { openNotification } from "../../../utils";
import Spinner from "../../../Components/Spinner";
import withRouter from "../../withRouter";
import "./index.css";
import $ from "jquery";
import * as XLSX from "xlsx";

class RepresentativeUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: true,
      isLoading: false,
      isView: true,
      imageFileName: "",
      image_pathStatus: "",
      image_file_path: "",
      emails: [],
      files: null,
    };
  }

  componentDidMount() {
    let route = this.props?.router?.location;
    if (Object.keys(route).length && route.state) {
      if (Object.keys(route?.state).length && !route?.state?.isView) {
        this.setState(
          { id: route?.state?.id, isView: route?.state?.isView },
          () => this.getRepresentativeView(true)
        );
      }
    }
  }

  readDataFromExcel = (data) => {
    const wb = XLSX.read(data);

    // setSheetNames(wb.SheetNames);

    var mySheetData = {};

    for (var i = 0; i < wb.SheetNames.length; i++) {
      let sheetName = wb.SheetNames[i];
      const worksheet = wb.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      mySheetData[sheetName] = jsonData;

      console.log("1--> ", sheetName);
    }

    let finalData = [];
    mySheetData["representative"].map((x) => {
      let tempObj = {
        firstname: x["Full Name *"],
        email: x["Email *"],
        phone_number: x["Phone Number *"],
        alt_phone_numer: x["Alt Phone Number"],
        organization_name: x["Company Name *"]
          ? x["Company Name *"].toUpperCase()
          : "",
        division_name: x["Division Name *"],
        manager_name: x["Manager Name *"],
        manager_contact: x["Manager Contact *"],
        manager_email: x["Manager Email"],
      };
      finalData.push(tempObj);
    });

    let checkRepData = [];
    if (finalData.length) {
      checkRepData = finalData.map((x) => ({
        firstname: x.firstname,
        email: x.email,
        phone_number:
          x.phone_number != undefined
            ? x.phone_number.toString()
            : x.phone_number,
        organization_name: x.organization_name,
        division_name: x.division_name,
        manager_name: x.manager_name,
        manager_contact:
          x.manager_contact != undefined
            ? x.manager_contact.toString()
            : x.manager_contact,
      }));
    }

    let checkDuplicatesEmail =
      checkRepData.filter((x, i) =>
        checkRepData.find((v, ind) => v.email == x.email && i != ind)
      ) || [];

    if (checkDuplicatesEmail && checkDuplicatesEmail.length) {
      openNotification(
        "danger",
        "Error",
        "Found a duplicates emails in uploaded Excel sheet, please remove those duplicates email and try again"
      );
      return;
    }

    let chechAllFields = checkRepData.every((x) =>
      Object.values(x).every((v) => v != undefined && v.length)
    );
    console.log("2--> ", finalData);
    console.log("chechAllFields ", !chechAllFields);

    if (!chechAllFields) {
      openNotification(
        "danger",
        "Error",
        "Please fill the all mandatory fields"
      );
      return;
    }

    let req_data = { data: finalData };
    axios
      .post(
        `${process.env.REACT_APP_API}/api/bulkCreate-representative`,
        req_data
      )
      .then((res) => {
        if (res.data.error) {
          openNotification("danger", "Error", res.data.msg);
        } else {
          let resp = res.data.data;
          if (Object.keys(resp).length) {
            openNotification("danger", "Error", res.data.msg);
            if (resp.email) {
              let tempEmails = resp.email.map((x) => x.email);
              this.setState({ emails: tempEmails });
            }
          } else {
            openNotification("success", "Success", res.data.msg);
            this.setState({
              files: null,
              emails: [],
              image_pathStatus: "",
              isSubmit: true,
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  fileUploadSave = (file, fileStatus) => {
    this.setState({ [fileStatus]: "start", emails: [] });
    setTimeout(() => {
      this.setState({ [fileStatus]: "progress" });
    }, 200);

    setTimeout(() => {
      this.setState({
        [fileStatus]: "success",
      });
    }, 400);

    const acceptableFileName = ["xlsx", "xls"];

    let files = file[0];

    if (!files) {
      this.setState({ files, isSubmit: true });
      return;
    }

    if (
      !acceptableFileName.includes(files.name.split(".").pop().toLowerCase())
    ) {
      openNotification("danger", "Error", "invalid file");
      return;
    }
    this.setState({ files, isSubmit: false });

    // this.getBase64(files, fileStatus)
    //   .then((result) => {
    //     var data = { file: result };
    //     this.setState({
    //       [fileName]: files.name,
    //         [keyName]: res.data.data.url,
    //       [fileStatus]: "success",
    //     });

    // axios
    //   .post(`${process.env.REACT_APP_API}/api/fileUpload`, data)
    //   .then((res) => {
    //     if (res.data.error) {
    //       this.setState({ isSubmit: false, isLoading: false });
    //       openNotification("danger", "Error", res.data.msg);
    //     } else {
    //       this.setState({
    //         [fileName]: files.name,
    //         [keyName]: res.data.data.url,
    //         [fileStatus]: "success",
    //       });
    //     }
    //   })
    //   .catch((err) => console.log(err));
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  };

  onSubmitUploadRepresentative = async () => {
    const data = await this.state.files.arrayBuffer();
    this.readDataFromExcel(data);
  };

  renderFileUploadSwitch = (key) => {
    switch (key) {
      case "start":
        return <span>File Upload Started...</span>;
      case "progress":
        return <span className="fileStatus">File Uploading...</span>;
      case "success":
        return (
          <span className="fileStatusSuccess">File Uploaded Successfully</span>
        );
      default:
        break;
    }
  };

  downloadFile = () => {
    window.location.href =
    process.env.REACT_APP_API + "/files/Representative_Template.xlsx";
  };

  render() {
    return (
      <>
        {this.state.isLoading && <Spinner />}
        <div className="">
          <div className="breadcrumbs mt-0 customBreadcrumbs">
            <div className="breadcrumbs-inner">
              <div className="row m-0">
                <div className="col-sm-12 p-0">
                  <div className="page-header">
                    <div className="page-title">
                      <h1>Dashboard</h1>
                      <div className="page-title">
                        <ol className="breadcrumb text-right">
                          <li>
                            <a
                              href="!#"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.router.navigate(
                                  "/representative/list"
                                );
                              }}
                            >
                              Representative
                            </a>
                          </li>
                          <li className="active">Upload</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="animated fadeIn commonLabel mt-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h1>Upload Representative Data</h1>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => this.downloadFile()}
                  >
                    Download sample excel
                  </button>
                </div>
                <div className="card-body card-block text-left">
                  <div className="form-group">
                    <input
                      type="file"
                      name="file"
                      id="file"
                      className="input-file"
                      accept=".xlsx, .xls, .csv"
                      onChange={(e) =>
                        this.fileUploadSave(e.target.files, "image_pathStatus")
                      }
                    />
                    <label
                      htmlFor="file"
                      className="btn btn-tertiary js-labelFile"
                    >
                      <i className="icon fa fa-check"></i>
                      <span className="js-fileName">Choose a file</span>
                    </label>
                  </div>

                  <div className="fileStatusDiv d-flex justify-content-center">
                    {this.renderFileUploadSwitch(this.state.image_pathStatus)}
                  </div>

                  <div className="row form-group d-flex justify-content-center">
                    <input
                      className="btn btn-outline-success CusBtn"
                      type="submit"
                      value="Submit"
                      disabled={this.state.isSubmit}
                      onClick={() => this.onSubmitUploadRepresentative()}
                    />
                  </div>
                  {this.state.emails && this.state.emails.length > 0 && (
                    <>
                      <div className="row col-md-6 text-center">
                        Please remove the below duplicates email and upload
                        again
                      </div>
                      <div className="row col-md-6 d-flex justify-content-center">
                        <blockquote className="blkq">
                          <p>{JSON.stringify(this.state.emails)}</p>
                        </blockquote>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(RepresentativeUpload);
