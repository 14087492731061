import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./index.css";

export default function Sidebar() {
  const navigate = useNavigate();
  const [type, setType] = useState("");

  useEffect(() => {
    let type = localStorage.getItem("type");
    setType(type);
  }, []);

  return (
    <>
      {/* Left Panel  */}
      <aside id="left-panel" className="left-panel">
        <a
          className="logo-lg"
          href="!#"
          onClick={(e) => {
            e.preventDefault();
            localStorage.clear();
            navigate("/doctor/list");
          }}
        >
          <img src="/assets/images/logo.png" alt="Logo" />
        </a>
        <nav className="navbar navbar-expand-sm navbar-default">
          <div id="main-menu" className="main-menu collapse navbar-collapse">
            <ul className="nav navbar-nav">
              {/* <li className="active">
                <a
                  href="!#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/doctor/list");
                  }}
                >
                  <i className="menu-icon fa fa-laptop"></i>Dashboard{" "}
                </a>
              </li> */}
              {/* <li className="menu-title">UI elements</li> */}
              {/* menu-title  */}
              {/* Doctor menu item */}
              {type == "Admin" && (
                <li className="menu-item-has-children dropdown">
                  <a
                    href="!#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    // aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {" "}
                    <i className="menu-icon fa fa-user-md"></i>
                    Doctor
                  </a>
                  <ul className="sub-menu children dropdown-menu">
                    {" "}
                    <li>
                      <i className="fa fa-address-book" aria-hidden="true"></i>
                      <a
                        href="!#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/doctor/register");
                        }}
                      >
                        Create Doctor
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-address-card" aria-hidden="true"></i>
                      <a
                        href="!#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/doctor/list");
                        }}
                      >
                        DoctorList
                      </a>
                    </li>
                  </ul>
                </li>
              )}
              {/* Representative menu item */}
              {type == "Admin" && (
                <li className="menu-item-has-children dropdown">
                  <a
                    href="!#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    // aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {" "}
                    <i className="menu-icon fa fa-user-secret"></i>
                    Representative
                  </a>
                  <ul className="sub-menu children dropdown-menu">
                    {" "}
                    <li>
                      <i className="fa fa-address-book" aria-hidden="true"></i>
                      <a
                        href="!#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/representative/register");
                        }}
                      >
                        Create
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-address-card" aria-hidden="true"></i>
                      <a
                        href="!#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/representative/list");
                        }}
                      >
                        List
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-address-card" aria-hidden="true"></i>
                      <a
                        href="!#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/representative/upload");
                        }}
                      >
                        Import
                      </a>
                    </li>
                  </ul>
                </li>
              )}
              {/* Medicine menu item */}
              <li className="menu-item-has-children dropdown">
                <a
                  href="!#"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  // aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  <i className="menu-icon fa fa-medkit"></i>
                  Medicine
                </a>
                <ul className="sub-menu children dropdown-menu">
                  {" "}
                  {type != "Admin" && (
                    <li>
                      <i className="fa fa-address-book" aria-hidden="true"></i>
                      <a
                        href="!#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/medicine/register");
                        }}
                      >
                        Create
                      </a>
                    </li>
                  )}
                  <li>
                    <i className="fa fa-address-card" aria-hidden="true"></i>
                    <a
                      href="!#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/medicine/list");
                      }}
                    >
                      List
                    </a>
                  </li>
                  {type != "Admin" && (
                    <li>
                      <i className="fa fa-address-card" aria-hidden="true"></i>
                      <a
                        href="!#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/medicine/upload");
                        }}
                      >
                        Import
                      </a>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </div>
          {/* navbar-collapse */}
        </nav>
      </aside>
      {/* #left-panel */}
    </>
  );
}
