import React, { Component } from "react";
import axios from "axios";
import { openNotification } from "../../../utils";
import Spinner from "../../../Components/Spinner";
import withRouter from "../../withRouter";
import "./index.css";

class RepresentativeRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      email: "",
      phone_number: "",
      alt_phone_numer: "",
      organization_name: "",
      division_name: "",
      manager_name: "",
      manager_contact: "",
      manager_email: "",
      isSubmit: false,
      isLoading: false,
      isView: true,
    };
  }

  componentDidMount() {}

  onSubmitCreateRepresentative = () => {
    const {
      firstname,
      email,
      phone_number,
      alt_phone_numer,
      organization_name,
      division_name,
      manager_name,
      manager_contact,
      manager_email,
    } = this.state;

    this.setState({ isSubmit: true, isLoading: true });

    var data = {
      firstname,
      email,
      phone_number,
      alt_phone_numer,
      organization_name,
      division_name,
      manager_name,
      manager_contact,
      manager_email,
    };

    axios
      .post(`${process.env.REACT_APP_API}/api/create-representative`, data)
      .then((res) => {
        // console.log("Result ==> ", res.data);
        if (res.data.error) {
          this.setState({ isSubmit: false, isLoading: false });
          openNotification("danger", "Error", res.data.msg);
        } else {
          this.setState({ isSubmit: false, isLoading: false });
          openNotification("success", "Success", res.data.msg);

          this.setState(
            {
              firstname: "",
              email: "",
              phone_number: "",
              alt_phone_numer: "",
              organization_name: "",
              division_name: "",
              manager_name: "",
              manager_contact: "",
              manager_email: "",
            },
            () => {
              window.location.href = "/representative/list";
            }
          );
        }
      })
      .catch((err) => console.log(err));
  };

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  render() {
    return (
      <>
        {this.state.isLoading && <Spinner />}

        <div className="">
          <div className="breadcrumbs mt-0 customBreadcrumbs">
            <div className="breadcrumbs-inner">
              <div className="row m-0">
                <div className="col-sm-12 p-0">
                  <div className="page-header">
                    <div className="page-title">
                      <h1>Dashboard</h1>
                      <div className="page-title">
                      <ol className="breadcrumb text-right">
                        <li>
                          <a
                            href="!#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.router.navigate(
                                "/representative/list"
                              );
                            }}
                          >
                            Representative
                          </a>
                        </li>
                        <li className="active">Create</li>
                      </ol>
                    </div>
                    </div>
                  </div>
                </div>
           
              </div>
            </div>
          </div>
        </div>

        <div className="animated fadeIn commonLabel mt-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                {/* <div className="card-header">
                  <strong>Create Representative</strong>
                  <small> Form</small>
                </div> */}
                <div className="card-header">
                  <h1>Create Representative</h1>
                </div>
                <div className="card-body card-block text-left">
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="firstname"
                          className="form-control-label required"
                        >
                          Full Name
                        </label>
                        <input
                          type="text"
                          id="firstname"
                          placeholder="Enter Your First Name"
                          className="form-control"
                          value={this.state.firstname}
                          onChange={(e) =>
                            this.onChange("firstname", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="email" className=" form-control-label required">
                          Email
                        </label>
                        <input
                          type="text"
                          id="email"
                          placeholder="Enter Your Email"
                          className="form-control"
                          value={this.state.email}
                          onChange={(e) =>
                            this.onChange("email", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="phone_number"
                          className=" form-control-label required"
                        >
                          Phone Number
                        </label>
                        <input
                          type="text"
                          id="phone_number"
                          placeholder="Enter Your Phone Number"
                          className="form-control"
                          value={this.state.phone_number}
                          onChange={(e) =>
                            this.onChange("phone_number", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="alt_phone_numer"
                          className=" form-control-label required"
                        >
                          Alt Phone Number
                        </label>
                        <input
                          type="text"
                          id="alt_phone_numer"
                          placeholder="Enter Your Alt Phone Number"
                          className="form-control"
                          value={this.state.alt_phone_numer}
                          onChange={(e) =>
                            this.onChange("alt_phone_numer", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="organization_name"
                          className=" form-control-label required"
                        >
                          Company Name
                        </label>
                        <input
                          type="text"
                          id="organization_name"
                          placeholder="Enter Your Company Name"
                          className="form-control"
                          value={this.state.organization_name}
                          onChange={(e) =>
                            this.onChange("organization_name", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="manager_name"
                          className=" form-control-label required"
                        >
                          Manager Name
                        </label>
                        <input
                          type="text"
                          id="manager_name"
                          placeholder="Enter Your Manager Name"
                          className="form-control"
                          value={this.state.manager_name}
                          onChange={(e) =>
                            this.onChange("manager_name", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="manager_contact"
                          className=" form-control-label required"
                        >
                          Manger Contact
                        </label>
                        <input
                          type="text"
                          id="manager_contact"
                          placeholder="Enter Your Manager Contact"
                          className="form-control"
                          value={this.state.manager_contact}
                          onChange={(e) =>
                            this.onChange("manager_contact", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="manager_email"
                          className=" form-control-label required"
                        >
                          Manager Email
                        </label>
                        <input
                          type="text"
                          id="manager_email"
                          placeholder="Enter Your Manager Email"
                          className="form-control"
                          value={this.state.manager_email}
                          onChange={(e) =>
                            this.onChange("manager_email", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="division_name"
                          className=" form-control-label required"
                        >
                          Division Name
                        </label>
                        <input
                          type="text"
                          id="division_name"
                          placeholder="Enter Your Division Name"
                          className="form-control"
                          value={this.state.division_name}
                          onChange={(e) =>
                            this.onChange("division_name", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row form-group d-flex justify-content-center">
                    <input
                      className="btn btn-outline-success CusBtn"
                      type="submit"
                      value="Submit"
                      disabled={this.state.isSubmit}
                      onClick={() => this.onSubmitCreateRepresentative()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(RepresentativeRegister);
