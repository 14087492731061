import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { openNotification } from "../../utils";
import Spinner from "../../Components/Spinner";
import "./index.css";

function ForgotPassword(props) {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [forgotData, setForgotData] = useState({
    email: "",
  });

  const setFuncValue = (bool) => {
    setLoading(bool);
  };

  const forgotPass = () => {
    const { email } = forgotData;
    let re = /\S+@\S+\.\S+/;
    if (email.trim() === "" || !re.test(email)) {
      openNotification("danger", "Error", "Please Enter a valid email address");
      return;
    }
    setLoading(true);

    var data = { email };
    axios
      .post(`${process.env.REACT_APP_API}/api/forgotPassword`, data)
      .then((res) => {
        if (res.data.error) {
          setFuncValue(false);
          openNotification("danger", "Error", res.data.msg);
        } else {
          setFuncValue(false);
          openNotification("success", "Success", res.data.msg);
          // navigate("/doctor/list");
          setForgotData({
            email: "",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const onChange = (key, value) => {
    setForgotData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="bg-dark content-fit">
        <div className="sufee-login d-flex align-content-center flex-wrap">
          <div className="container">
            <div className="login-content">
              <div className="logo-form mb-1">
                <div className="login-logo">
                  <a href="!#" onClick={(e) => e.preventDefault()}>
                    <img
                      className="align-content"
                      src="/assets/images/logo.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="login-form">
                <form>
                  <div className="form-group">
                    <label>Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      onChange={(e) => onChange("email", e.target.value)}
                      required
                      value={forgotData.email}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-flat m-b-30 m-t-30"
                    onClick={(e) => {
                      e.preventDefault();
                      forgotPass();
                    }}
                  >
                    Submit
                  </button>
                  <div className="register-link m-t-15 text-center">
                    {/* <p>
                      Already have account ?{" "}
                      <a
                        href="!#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/");
                        }}
                      >
                        {" "}
                        Sign in
                      </a>
                    </p> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
