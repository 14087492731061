import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { openNotification } from "../../utils";
import Spinner from "../../Components/Spinner";
import DataTable from "react-data-table-component";
import DatalistInput from "react-datalist-input";
import "react-datalist-input/dist/styles.css";
import $ from "jquery";
import moment from "moment";
import * as XLSX from "xlsx";

function List(props) {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [hideColumn, setHideColumn] = useState(false);
  const [listData, setListData] = useState([]);
  const [details, setDetails] = useState({});
  const [page, setPage] = useState(1);
  const [totalCount, setCount] = useState(0);
  const [selectedAltMedId, setSelectedAltMedId] = useState(0);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [deleteIDSelect, setDeleteIDSelect] = useState("");
  const [type, setType] = useState("");
  const [medicineData, setMedicineData] = useState([]);

  const getAllList = (noti = false) => {
    let searchVal = searchName.length ? searchName : 0;
    setLoading(noti);
    axios
      .get(
        `${process.env.REACT_APP_API}/api/getAllMedicineDetails/${
          searchVal ? 1 : page
        }/${perPage ? perPage : 10}/${searchVal}`
      )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          setCount(0);
          setListData([]);
          // openNotification("danger", "Error", res.data.msg);
        } else {
          let data = res.data.data;
          let tempData = data.data.map((x) => {
            x.organization_name = x.organization_name
              ? x.organization_name.toUpperCase()
              : "";
            return x;
          });
          setCount(data.count);
          setListData(tempData);
        }
      })
      .catch((err) => console.log(err));
  };

  const getAllMedicine = (noti = false) => {
    setLoading(noti);
    axios
      .get(`${process.env.REACT_APP_API}/api/getAllMedicineDetails`)
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          // openNotification("danger", "Error", res.data.msg);
        } else {
          let data = res.data;
          let tempData = [...data?.data]
            ?.filter((x) => x.id != deleteIDSelect)
            ?.map((data) => {
              data["id"] = data.id;
              data["value"] = data.medicine_name;
              return data;
            });
          setMedicineData(tempData);
        }
      })
      .catch((err) => console.log(err));
  };

  const getExportMedicineData = (noti = false) => {
    setLoading(noti);
    axios
      .get(`${process.env.REACT_APP_API}/api/getExportAllMedicineDetails`)
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          openNotification("danger", "Error", res.data.msg);
        } else {
          let data = res.data.data;
          setLoading(false);

          let finalData = [];
          data.map((x) => {
            let tempObj = {
              medicine_name: x.medicine_name,
              molecule: x.molecule,
              mrp: x.mrp,
              description: x.description,
              type: x.type,
              distributer_name: x.distributer_name,
              mobile: x.mobile,
              alt_distributer_name: x.alt_distributer_name,
              alt_mobile: x.alt_mobile,
              created_at: moment(x.created_at).format("DD-MM-YYYY hh:mm a"),
              updated_at: x.updated_at
                ? moment(x.updated_at).format("DD-MM-YYYY hh:mm a")
                : "-",
            };
            finalData.push(tempObj);
          });
          /* generate worksheet and workbook */
          const worksheet = XLSX.utils.json_to_sheet(finalData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Medicine");
          XLSX.utils.sheet_add_aoa(
            worksheet,
            [
              [
                "Product/Brand Name",
                "Molecule",
                "MRP",
                "Remark",
                "Formulation",
                "Distributer Name",
                "Distributer Mobile",
                "Alt Distributer Name",
                "Alt Distributer Mobile",
                "Created",
                "Updated",
              ],
            ],
            {
              origin: "A1",
            }
          );
          const max_width = finalData.reduce(
            (w, r) => Math.max(w, r.medicine_name.length),
            10
          );
          worksheet["!cols"] = [{ wch: max_width }];
          XLSX.writeFile(
            workbook,
            `Medicine-${moment().format("DD-MM-YYYY")}.xlsx`,
            {
              compression: true,
            }
          );
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllMedicine();
  }, [deleteIDSelect]);

  const selectDeleteId = (id, val) => {
    if (val) {
      deleteMedicine(id, val);
    } else {
      // getAllMedicine();
      setDeleteIDSelect(id);
      $("#deleteModalTrigger").click();
    }
  };

  const deleteMedicine = (id, val, noti = false) => {
    setLoading(noti);
    let list_data = listData.map((x) => {
      if (id == x.id) x.status = val;
      return x;
    });
    setListData(list_data);
    const data = {
      id,
      status: val,
      alternative_id: selectedAltMedId,
    };
    axios
      .post(`${process.env.REACT_APP_API}/api/delete-medicine`, data)
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          $("#close-confirmModal").click();
          openNotification("danger", "Error", res.data.msg);
        } else {
          setLoading(false);
          $("#close-confirmModal").click();
          openNotification("success", "Success", res.data.msg);
          // getAllList();
        }
      })
      .catch((err) => console.log(err));
  };

  const openMedicineForm = (id, route, isView) => {
    navigate(`/medicine/${route}`, {
      state: {
        id,
        isView,
      },
    });
  };

  const selectedDetails = (id) => {
    axios
      .get(`${process.env.REACT_APP_API}/api/getOneMedicineDetails/${id}`)
      .then((res) => {
        if (res.data.error) {
          openNotification("danger", "Error", res.data.msg);
        } else {
          let list_data = res.data.data;
          let tempData = list_data.map((x) => {
            x.organization_name = x.organization_name
              ? x.organization_name.toUpperCase()
              : "";
            return x;
          });
          setDetails(tempData[0]);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const type = localStorage.getItem("type");
    setType(type);
    setHideColumn(type == "Rep");
    getAllList();
  }, []);
  useEffect(() => getAllList(), [page]);
  useEffect(() => getAllList(), [perPage]);

  const setSearchValue = (e) => {
    const { value } = e.target;
    setSearchName(value.trimStart());
  };

  const MedicineDataListComp = () => (
    <DatalistInput
      placeholder="Alternative Medicine"
      label="Select the alternative medicine"
      onSelect={(item) => setSelectedAltMedId(item.id)}
      items={medicineData}
    />
  );
  const tableCustomStyles = {
    headRow: {
      style: {
        color: "#194688",
        backgroundColor: "#E1EBFF",
        borderBottom: "0",
      },
    },
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "bold",
        justifyContent: "center",
        backgroundColor: "#E1EBFF",
        borderBottom: "1px solid rgba(0,0,0,.12)",
      },
    },
  };
  const MedicineDataTable = () => {
    const columns = useMemo(
      () => [
        {
          name: "S.No",
          id: "sno",
          selector: (row, index) => (page - 1) * 10 + (index + 1),
          center: true,
          width: "5%",
        },
        {
          name: "Representative Name",
          selector: (row) => {
            return row.firstname;
          },
          sortable: true,
          center: true,
          omit: hideColumn,
          wrap: true,
        },
        {
          name: "Company",
          selector: "organization_name",
          sortable: true,
          center: true,
          wrap: true,
          // width: "16%",
          omit: hideColumn,
        },
        {
          name: "Distributer Name",
          selector: "distributer_name",
          sortable: true,
          center: true,
          wrap: true,
        },
        {
          name: "Phone",
          selector: "phone_number",
          sortable: true,
          center: true,
          wrap: true,
          omit: hideColumn,
        },
        {
          name: "Product/Brand Name",
          selector: "medicine_name",
          sortable: true,
          center: true,
          width: "15%",
          wrap: true,
        },
        {
          name: "MRP",
          selector: "mrp",
          sortable: true,
          width: "6%",
          center: true,
        },
        {
          name: "Molecule",
          selector: "molecule",
          sortable: true,
          center: true,
          wrap: true,
        },
        {
          name: "Formulation",
          selector: "type",
          sortable: true,
          center: true,
          wrap: true,
        },
        {
          name: "Created",
          cell: (row) => {
            return row.created_at ? (
              <span>{moment(row.created_at).format("DD-MM-YYYY")}</span>
            ) : (
              <span>-</span>
            );
          },
          sortable: true,
          center: true,
        },
        {
          name: "Updated",
          cell: (row) => {
            return row.updated_at ? (
              <span>{moment(row.updated_at).format("DD-MM-YYYY")}</span>
            ) : (
              <span>-</span>
            );
          },
          sortable: true,
          center: true,
        },
        {
          name: "Status",
          center: true,
          cell: (row) => {
            return row.status ? (
              <span className="badge badge-pill badge-success">Active</span>
            ) : (
              <span className="badge badge-pill badge-danger">Inactive</span>
            );
          },
        },
        {
          name: "Action",
          center: true,
          // eslint-disable-next-line react/button-has-type
          cell: (represent) => {
            return (
              <>
                <div className="list_action">
                  <span
                    className="cursor-pointer"
                    data-toggle="modal"
                    data-target=".bd-example-modal-lg"
                    onClick={() => {
                      selectedDetails(represent.id);
                    }}
                  >
                    <i className="fa fa-eye"></i>
                  </span>
                  &emsp;
                  <span>
                    <div class="check-box">
                      <input
                        type="checkbox"
                        checked={represent.status}
                        onChange={
                          (e) => selectDeleteId(represent.id, e.target.checked)
                          // deleteMedicine(represent.id, e.target.checked)
                        }
                      />{" "}
                    </div>
                    {/* <label className="switch">
                      <input
                        type="checkbox"
                        checked={represent.status}
                        onChange={
                          (e) => selectDeleteId(represent.id, e.target.checked)
                         
                        }
                      />{" "}
                      <div></div>
                    </label> */}
                  </span>
                  <span
                    style={{ visibility: "hidden" }}
                    data-toggle="modal"
                    data-target="#deleteMedicineModal"
                    id="deleteModalTrigger"
                  ></span>
                  &emsp;
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      openMedicineForm(represent.id, "update", false);
                    }}
                  >
                    <i className="fa fa-edit"></i>
                  </span>
                </div>
              </>
            );
          },
        },
      ]
      // [handleDelete]
    );

    const handlePageChange = (npage) => {
      // console.log("page ==> ", page, npage);
      setPage(npage);
      if (page != npage) getAllList();
    };

    const handlePerPageChange = (nperPage) => {
      // console.log("page ==> ", perPage);
      setPerPage(nperPage);
      if (perPage != nperPage) getAllList();
    };

    return (
      <div className="res cusWidth">
        <DataTable
          columns={columns}
          data={listData}
          customStyles={tableCustomStyles}
          // progressPending={loading}
          pagination
          paginationServer
          paginationPerPage={perPage}
          paginationTotalRows={totalCount}
          paginationDefaultPage={page}
          onChangeRowsPerPage={handlePerPageChange}
          onChangePage={handlePageChange}
          // selectableRows
          // onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
        />
      </div>
    );
  };

  return (
    <>
      {isLoading && <Spinner />}

      <div className="">
        <div className="breadcrumbs mt-0 customBreadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-sm-7 p-0">
                <div className="page-header">
                  <div className="page-title">
                    <h1>Dashboard</h1>
                    <div className="page-title">
                      <ol className="breadcrumb text-right">
                        <li>Medicine</li>
                        <li className="active">Medicine List</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-5 p-0 d-flex justify-content-end">
                <div className="main p-relative mr-2">
                  {/* Actual search box  */}
                  <div className="form-group has-search tableSearch">
                    <button
                      className="btn btn-primary btn-search-align"
                      onClick={() => getAllList()}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                    {/* <span className="fa fa-search form-control-feedback"></span> */}
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchName}
                      onChange={setSearchValue}
                    />
                  </div>
                </div>
                {type != "Admin" && (
                  <div className="ml-2">
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => navigate("/medicine/register")}
                    >
                      Add New
                    </button>
                  </div>
                )}
                {type == "Admin" && (
                  <div className="ml-2">
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => getExportMedicineData(true)}
                    >
                      Export Excel
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="animated fadeIn">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body p-0">
                <MedicineDataTable />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* View Details modal  */}
      <div
        className="modal fade bd-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title h5" id="myLargeModalLabel">
                Medicine Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body popupLable2">
              <div className="row">
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>Medicine Name: </span>
                    {details.medicine_name}
                  </h5>
                </div>
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>Molecule Name: </span>
                    {details.molecule}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>MRP: </span>
                    {details.mrp}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>Formulation:</span>
                    {details.type}
                  </h5>
                </div>
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>Remark:</span>
                    {details.description}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className="py-3">
                    <span>Representative Name:</span>
                    {details.firstname}
                  </h5>
                </div>
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>Mobile Number:</span> {details.phone_number}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className="float-left py-3">
                    <span>Distributer Name:</span> {details.distributer_name}
                  </h5>
                </div>
                <div className="col-md-6">
                  <h5 className="float-left py-3">
                    <span>Mobile Number:</span> {details.mobile}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>Alt Distributer Name:</span>
                    {details.alt_distributer_name}
                  </h5>
                </div>
                <div className="col-md-6">
                  <h5 className="float-left py-3">
                    <span>Mobile Number:</span> {details.alt_mobile}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className="float-left py-3">
                    <span>Company Name:</span> {details.organization_name}
                  </h5>
                </div>
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>Status:</span>
                    {details.status ? "Active" : "Inactive"}
                  </h5>
                </div>
              </div>
              {details.repMedicineName && (
                <div className="row">
                  <div className="col-md-8">
                    <h5 className=" py-3">
                      <span>Alternative Medicine: </span>

                      <a
                        className="cursor-pointer a-href"
                        onClick={(e) => {
                          e.preventDefault();
                          selectedDetails(details.alternative_id);
                        }}
                      >
                        {details.repMedicineName}
                      </a>
                    </h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="deleteMedicineModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex">
              <div>
                <h5 className="modal-title" id="staticModalLabel">
                  Inactive Medicine
                </h5>
              </div>
              <div>
                <span
                  className="cursor-pointer close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close"></i>
                </span>
              </div>
            </div>
            <div className="modal-body">
              <strong>
                Are you sure, you want to deactivate this medicine ?
              </strong>
              <MedicineDataListComp />
              {medicineData && !medicineData.length && `No data found`}
            </div>
            <div className="modal-footer">
              <button
                id="close-confirmModal"
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => deleteMedicine(deleteIDSelect, false)}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
