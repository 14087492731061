import React from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
// import Footer from "../../Components/Footer";
import "./index.css";

function DashBoard(props) {
  return (
    <>
      <div id="SideBar" className="">
        <Sidebar />
        <div id="right-panel" className="right-panel">
          <Navbar />
          {/* Content  */}
          <div className="content">
            {/* Widgets  */}
            <props.component />

            {/* Modal - Calendar - Add New Event */}
            {/* <div className="modal fade none-border" id="event-modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                <h4 className="modal-title"><strong>Add New Event</strong></h4>
                            </div>
                            <div className="modal-body"></div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-success save-event waves-effect waves-light">Create event</button>
                                <button type="button" className="btn btn-danger delete-event waves-effect waves-light" data-dismiss="modal">Delete</button>
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* #event-modal  */}
            {/* Modal - Calendar - Add Category  */}
            {/* <div className="modal fade none-border" id="add-category">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                <h4 className="modal-title"><strong>Add a category </strong></h4>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="control-label">Category Name</label>
                                            <input className="form-control form-white" placeholder="Enter name" type="text" name="category-name"/>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="control-label">Choose Category Color</label>
                                            <select className="form-control form-white" data-placeholder="Choose a color..." name="category-color">
                                                <option value="success">Success</option>
                                                <option value="danger">Danger</option>
                                                <option value="info">Info</option>
                                                <option value="pink">Pink</option>
                                                <option value="primary">Primary</option>
                                                <option value="warning">Warning</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-danger waves-effect waves-light save-category" data-dismiss="modal">Save</button>
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* #add-category  */}
          </div>
          {/* content  */}
          {/* <Footer /> */}
        </div>
        {/* #right-panel  */}
      </div>
    </>
  );
}

export default DashBoard;
