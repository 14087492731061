import React, { useEffect, useState, useMemo, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { openNotification } from "../../utils";
import Spinner from "../../Components/Spinner";
import DataTable from "react-data-table-component";
import $ from "jquery";

function List(props) {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [page, setPage] = useState(1);
  const [totalCount, setCount] = useState(0);

  const [perPage, setPerPage] = useState(10);
  const [searchName, setSearchName] = useState("");

  const getAllList = (noti = false) => {
    setLoading(noti);
    axios
      .get(
        `${process.env.REACT_APP_API}/api/getAllDoctorDetails/${page}/${perPage ? perPage : 10}/${
          searchName.length ? searchName : 0
        }`
      )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          openNotification("danger", "Error", res.data.msg);
        } else {
          let data = res.data.data;
          setCount(data.count);
          setListData(data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteDoctor = (noti = false) => {
    setLoading(noti);
    const selectedData = listData.find((x) => x.id === selectedId);
    const data = { id: selectedData.id };
    axios
      .post(`${process.env.REACT_APP_API}/api/delete-doctor`, data)
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          $("#close-confirmModal").click();
          openNotification("danger", "Error", res.data.msg);
        } else {
          setLoading(false);
          $("#close-confirmModal").click();
          openNotification("success", "Success", res.data.msg);
          getAllList();
        }
      })
      .catch((err) => console.log(err));
  };

  const openForm = (id, route, isView) => {
    navigate(`/doctor/${route}`, {
      state: {
        id,
        isView,
      },
    });
  };

  useEffect(() => getAllList(), []);
  useEffect(() => getAllList(), [page]);
  useEffect(() => getAllList(), [perPage]);

  const setSearchValue = (e) => {
    const { value } = e.target;
    setSearchName(value.trimStart());
  };
  const tableCustomStyles = {
    headRow: {
      style: {
        color: "#194688",
        backgroundColor: "#E1EBFF",
      },
    },
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "bold",
        justifyContent: "center",
      },
    },
  };
  const RepresentativeDataTable = () => {
    const columns = useMemo(
      () => [
        {
          name: "S.No",
          id: "sno",
          center: true,
          style: {
            color: "#313131",
            fontWeight: "500",
          },
          selector: (row, index) => (page - 1) * 10 + (index + 1),
        },
        {
          name: "Profile",
          center: true,
          style: {
            color: "#313131",
            fontWeight: "500",
          },
          cell: (represent) => {
            return (
              <>
                <img
                  className="rounded-circle mx-auto d-block"
                  src={`${process.env.REACT_APP_FILE_URL}${represent.image_file_path}`}
                  alt="User Profile Image"
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "12px",
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/assets/images/userPlaceholder.png";
                  }}
                />
              </>
            );
          },
        },
        {
          name: "Doctor Name",
          selector: "firstname",
          center: true,
          sortable: true,
          style: {
            color: "#313131",
            fontWeight: "500",
          },
        },
        {
          name: "Email",
          selector: "email",
          center: true,
          sortable: true,
          style: {
            color: "#313131",
            fontWeight: "500",
          },
        },
        {
          name: "Designation",
          selector: "designation",
          center: true,
          sortable: true,
          style: {
            color: "#313131",
            fontWeight: "500",
          },
        },
        {
          name: "Action",
          center: true,
          // eslint-disable-next-line react/button-has-type
          cell: (represent) => {
            return (
              <>
                <div className="list_action">
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      openForm(represent.id, "view", true);
                    }}
                  >
                    <i className="fa fa-eye"></i>
                  </span>
                  &emsp;
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      openForm(represent.id, "update", false);
                    }}
                  >
                    <i className="fa fa-edit"></i>
                  </span>
                  &emsp;
                  <span
                    className="cursor-pointer"
                    data-toggle="modal"
                    data-target="#staticModal"
                    onClick={() => setSelectedId(represent.id)}
                  >
                    <i className="fa fa-trash-o"></i>
                  </span>
                </div>
              </>
            );
          },
        },
      ]
      // [handleDelete]
    );

    const handlePageChange = (npage) => {
      // console.log("page ==> ", perPage);
      setPage(npage);
      if (page != npage) getAllList();
    };

    const handlePerPageChange = (nperPage) => {
      // console.log("page ==> ", perPage);
      setPerPage(nperPage);
      if (perPage != nperPage) getAllList();
    };

    return (
      <DataTable
        columns={columns}
        data={listData}
        customStyles={tableCustomStyles}
        pagination
        paginationServer
        paginationPerPage={perPage}
        paginationTotalRows={totalCount}
        paginationDefaultPage={page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
      />
    );
  };

  return (
    <>
      {isLoading && <Spinner />}

      <div className="">
        <div className="breadcrumbs mt-0 customBreadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0 align-items-center">
              <div className="col-sm-7 p-0">
                <div className="page-header">
                  <div className="page-title">
                    <h1>Dashboard</h1>
                    <div className="page-title">
                      <ol className="breadcrumb text-right">
                        <li>Doctor</li>
                        <li className="active">Doctors List</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="main p-relative">
                  {/* Actual search box  */}
                  <div className="form-group has-search tableSearch">
                    <button
                      className="btn btn-primary btn-search-align"
                      onClick={() => getAllList()}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                    {/* <span className="fa fa-search form-control-feedback"></span> */}
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchName}
                      onChange={setSearchValue}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="animated fadeIn">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body p-0">
                <RepresentativeDataTable />
                {/* <th scope="col">Qualification</th> */}
                {/* <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Profile</th>
                      <th scope="col">Doctor Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listData && listData.length > 0 ? (
                      <>
                        {listData.map((doctor, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              <img
                                className="rounded-circle mx-auto d-block"
                                src={`${process.env.REACT_APP_FILE_URL}${doctor.image_file_path}`}
                                alt="User Profile Image"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    "/assets/images/userPlaceholder.png";
                                }}
                              />
                            </td>
                            <td>{doctor.firstname}</td>
                            <td>{doctor.email}</td>
                            <td>{doctor.designation}</td>
                            <td>
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  openForm(doctor.id, "view", true);
                                }}
                              >
                                <i className="fa fa-eye"></i>
                              </span>
                              &emsp;
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  openForm(doctor.id, "update", false);
                                }}
                              >
                                <i className="fa fa-edit"></i>
                              </span>
                              &emsp;
                              <span
                                className="cursor-pointer"
                                data-toggle="modal"
                                data-target="#staticModal"
                                onClick={() => setSelectedId(doctor.id)}
                              >
                                <i className="fa fa-trash-o"></i>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={6}>No Doctors record found</td>
                      </tr>
                    )}
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="staticModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex">
              <div>
                <h5 className="modal-title" id="staticModalLabel">
                  Delete User
                </h5>
              </div>
              <div>
                <span
                  className="cursor-pointer close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close"></i>
                </span>
              </div>
            </div>
            <div className="modal-body">
              <strong>Are you sure, you want to delete this ?</strong>
            </div>
            <div className="modal-footer">
              <button
                id="close-confirmModal"
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => deleteDoctor(true)}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
