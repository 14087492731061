import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { openNotification } from "../../utils";
import Spinner from "../../Components/Spinner";
import DataTable from "react-data-table-component";
import moment from "moment";
import * as XLSX from "xlsx";
// import $ from "jquery";

function List(props) {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [details, setDetails] = useState({});
  const [page, setPage] = useState(1);
  const [totalCount, setCount] = useState(0);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState("");

  const getAllList = (noti = false) => {
    let searchVal = searchName.length ? searchName : 0;
    setLoading(noti);
    axios
      .get(
        `${process.env.REACT_APP_API}/api/getAllRepresentativeDetails/${
          searchVal ? 1 : page
        }/${perPage ? perPage : 10}/${searchVal}`
      )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          setCount(0);
          setListData([]);
          // openNotification("danger", "Error", res.data.msg);
        } else {
          let data = res.data.data;
          let tempData = data.data.map((x) => {
            x.organization_name = x.organization_name
              ? x.organization_name.toUpperCase()
              : "";
            return x;
          });
          setCount(data.count);
          setListData(tempData);
        }
      })
      .catch((err) => console.log(err));
  };

  const getExportRepData = (noti = false) => {
    setLoading(noti);
    axios
      .get(`${process.env.REACT_APP_API}/api/getExportAllRepresentativeDetails`)
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          openNotification("danger", "Error", res.data.msg);
        } else {
          let data = res.data.data;
          setLoading(false);

          let finalData = [];
          data.map((x) => {
            let tempObj = {
              firstname: x.firstname,
              email: x.email,
              phone_number: x.phone_number,
              alt_phone_numer: x.alt_phone_numer,
              organization_name: x.organization_name
                ? x.organization_name.toUpperCase()
                : "",
              division_name: x.division_name,
              manager_name: x.manager_name,
              manager_contact: x.manager_contact,
              manager_email: x.manager_email,
              created_at: moment(x.created_at).format("DD-MM-YYYY hh:mm a"),
              updated_at: x.updated_at
                ? moment(x.updated_at).format("DD-MM-YYYY hh:mm a")
                : "-",
            };
            finalData.push(tempObj);
          });
          /* generate worksheet and workbook */
          const worksheet = XLSX.utils.json_to_sheet(finalData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Representative");
          XLSX.utils.sheet_add_aoa(
            worksheet,
            [
              [
                "Full Name",
                "Email",
                "Phone Number",
                "Alt Phone Number",
                "Company Name",
                "Division Name",
                "Manager Name",
                "Manager Contact",
                "Manager Email",
                "Created",
                "Updated",
              ],
            ],
            {
              origin: "A1",
            }
          );
          const max_width = finalData.reduce(
            (w, r) => Math.max(w, r.firstname.length),
            10
          );
          worksheet["!cols"] = [{ wch: max_width }];
          XLSX.writeFile(
            workbook,
            `Representative-${moment().format("DD-MM-YYYY")}.xlsx`,
            {
              compression: true,
            }
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteRepresentative = (id, val, noti = false) => {
    setLoading(noti);
    let list_data = listData.map((x) => {
      if (id == x.id) x.status = val;
      return x;
    });
    setListData(list_data);
    const data = { id, status: val };
    axios
      .post(`${process.env.REACT_APP_API}/api/delete-representative`, data)
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          // $("#close-confirmModal").click();
          openNotification("danger", "Error", res.data.msg);
        } else {
          setLoading(false);
          // $("#close-confirmModal").click();
          openNotification("success", "Success", res.data.msg);
          // getAllList();
        }
      })
      .catch((err) => console.log(err));
  };

  const openRepresentativeForm = (id, route, isView) => {
    navigate(`/representative/${route}`, {
      state: {
        id,
        isView,
      },
    });
  };

  const selectedDetails = (id) => {
    let list_data = listData.filter((x) => x.id === id);
    setDetails(list_data[0]);
  };

  useEffect(() => getAllList(), []);
  useEffect(() => getAllList(), [page]);
  useEffect(() => getAllList(), [perPage]);

  const setSearchValue = (e) => {
    const { value } = e.target;
    setSearchName(value.trimStart());
  };

  const tableCustomStyles = {
    headRow: {
      style: {
        color: "#194688",
        backgroundColor: "#E1EBFF",
      },
    },
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "bold",
        justifyContent: "center",
      },
    },
  };

  const RepresentativeDataTable = () => {
    const columns = useMemo(
      () => [
        {
          name: "S.No",
          id: "sno",
          selector: (row, index) => (page - 1) * 10 + (index + 1),
          center: true,
          responsive: true,
        },
        {
          name: "Name",
          selector: "firstname",
          sortable: true,
          center: true,
          responsive: true,
        },
        {
          name: "Email",
          selector: "email",
          sortable: true,
          center: true,
        },
        {
          name: "Phone",
          selector: "phone_number",
          sortable: true,
          center: true,
        },
        {
          name: "Company",
          selector: "organization_name",
          sortable: true,
          center: true,
        },
        {
          name: "Division",
          selector: "division_name",
          sortable: true,
          center: true,
        },
        {
          name: "Created",
          cell: (row) => {
            return row.created_at ? (
              <span>{moment(row.created_at).format("DD-MM-YYYY")}</span>
            ) : (
              <span>-</span>
            );
          },
          sortable: true,
          center: true,
        },
        {
          name: "Updated",
          cell: (row) => {
            return row.updated_at ? (
              <span>{moment(row.updated_at).format("DD-MM-YYYY")}</span>
            ) : (
              <span>-</span>
            );
          },
          sortable: true,
          center: true,
        },
        {
          name: "Status",
          center: true,
          cell: (row) => {
            return row.status ? (
              <span className="badge badge-pill badge-success">Active</span>
            ) : (
              <span className="badge badge-pill badge-danger">Inactive</span>
            );
          },
        },
        {
          name: "Action",
          center: true,
          // eslint-disable-next-line react/button-has-type
          cell: (represent) => {
            return (
              <>
                <div className="list_action">
                  <span
                    className="cursor-pointer"
                    data-toggle="modal"
                    data-target=".bd-example-modal-lg"
                    onClick={() => {
                      selectedDetails(represent.id);
                    }}
                  >
                    <i className="fa fa-eye"></i>
                  </span>
                  &emsp;
                  <span>
                    <div class="check-box">
                      <input
                        type="checkbox"
                        checked={represent.status}
                        onChange={(e) =>
                          deleteRepresentative(represent.id, e.target.checked)
                        }
                      />{" "}
                    </div>
                    {/* <label className="switch">
                      <input
                        type="checkbox"
                        checked={represent.status}
                        onChange={(e) =>
                          deleteRepresentative(represent.id, e.target.checked)
                        }
                      />{" "}
                      <div></div>
                    </label> */}
                  </span>
                  &emsp;
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      openRepresentativeForm(represent.id, "update", false);
                    }}
                  >
                    <i className="fa fa-edit"></i>
                  </span>
                </div>
              </>
            );
          },
        },
      ]
      // [handleDelete]
    );

    const handlePageChange = (npage) => {
      // console.log("page ==> ", page, npage);
      setPage(npage);
      if (page != npage) getAllList();
    };

    const handlePerPageChange = (nperPage) => {
      // console.log("page ==> ", perPage);
      setPerPage(nperPage);
      if (perPage != nperPage) getAllList();
    };

    return (
      <div className="res cusWidth">
        <DataTable
          responsive
          columns={columns}
          data={listData}
          // progressPending={loading}
          customStyles={tableCustomStyles}
          pagination
          paginationServer
          paginationPerPage={perPage}
          paginationTotalRows={totalCount}
          paginationDefaultPage={page}
          onChangeRowsPerPage={handlePerPageChange}
          onChangePage={handlePageChange}
          // selectableRows
          // onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
        />
      </div>
    );
  };

  return (
    <>
      {isLoading && <Spinner />}

      <div className="">
        <div className="breadcrumbs mt-0 customBreadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-sm-7 p-0">
                <div className="page-header float-left">
                  <div className="page-title">
                    <h1>Dashboard</h1>
                    <div className="page-title">
                      <ol className="breadcrumb text-right">
                        <li>Representative</li>
                        <li className="active">Representative List</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-5 p-0 d-flex justify-content-end">
                <div className="main p-relative mr-2">
                  {/* Actual search box  */}
                  <div className="form-group has-search tableSearch">
                    <button
                      className="btn btn-primary btn-search-align"
                      onClick={() => getAllList()}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                    {/* <span className="fa fa-search form-control-feedback"></span> */}
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchName}
                      onChange={setSearchValue}
                    />
                  </div>
                </div>
                <div className="ml-2">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => navigate("/representative/register")}
                  >
                    Add New
                  </button>
                </div>
                <div className="ml-2">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => getExportRepData(true)}
                  >
                    Export Excel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="animated fadeIn">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body p-0">
                <RepresentativeDataTable />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* View Details modal  */}
      <div
        className="modal fade bd-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title h5" id="myLargeModalLabel">
                Representative Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body popupLable">
              <div className="row">
                <div className="col-md-6">
                  <h5 className="float-left py-3">
                    <span>Full Name:</span> {details.firstname}
                  </h5>
                </div>
                <div className="col-md-6">
                  <h5 className="float-left py-3">
                    <span>Phone Number:</span> {details.phone_number}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className="float-left py-3">
                    <span>Email:</span> {details.email}
                  </h5>
                </div>
                <div className="col-md-6">
                  <h5 className="float-left py-3">
                    <span>Alt Phone Number:</span> {details.alt_phone_numer}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>Company Name:</span>
                    {details.organization_name}
                  </h5>
                </div>
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>Division Name:</span>
                    {details.division_name}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>Manager Name:</span>
                    {details.manager_name}
                  </h5>
                </div>
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>Manager Contact:</span>
                    {details.manager_contact}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>Manager Email:</span>
                    {details.manager_email}
                  </h5>
                </div>
                <div className="col-md-6">
                  <h5 className=" py-3">
                    <span>Status:</span>
                    {details.status ? "Active" : "Inactive"}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex">
              <div>
                <h5 className="modal-title" id="staticModalLabel">
                  Delete User
                </h5>
              </div>
              <div>
                <span
                  className="cursor-pointer close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close"></i>
                </span>
              </div>
            </div>
            <div className="modal-body">
              <strong>Are you sure, you want to delete this ?</strong>
            </div>
            <div className="modal-footer">
              <button
                id="close-confirmModal"
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => deleteRepresentative(true)}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
