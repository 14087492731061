import React, { Component } from "react";
import axios from "axios";
import { openNotification } from "../../../utils";
import Spinner from "../../../Components/Spinner";
import withRouter from "../../withRouter";
import "./index.css";

class MedicineUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      medicine_name: "",
      molecule: "",
      type: "",
      mrp: "",
      description: "",
      mobile: "",
      alt_mobile: "",
      distributer_name: "",
      alt_distributer_name: "",
      rep_id: 0,
      isSubmit: false,
      isLoading: false,
      isView: true,
      medicineTypes: [],
    };
  }

  componentDidMount() {
    let route = this.props?.router?.location;
    if (Object.keys(route).length && route.state) {
      if (Object.keys(route?.state).length && !route?.state?.isView) {
        this.getMedicineTypes();
        this.setState(
          { id: route?.state?.id, isView: route?.state?.isView },
          () => this.getRepresentativeView(true)
        );
      }
    }
  }

  getRepresentativeView = (noti = false) => {
    const { id } = this.state;

    this.setState({ isLoading: noti });
    axios
      .get(`${process.env.REACT_APP_API}/api/getMedicineDetails/${id}`)
      .then((res) => {
        if (res.data.error) {
          this.setState({ isLoading: false });
          openNotification("danger", "Error", res.data.msg);
        } else {
          let data = res.data.data;
          this.setState({ isLoading: false, ...data });
        }
      })
      .catch((err) => console.log(err));
  };

  onSubmitUpdateMedicine = () => {
    const {
      id,
      rep_id,
      medicine_name,
      molecule,
      type,
      mrp,
      description,
      mobile,
      alt_distributer_name,
      alt_mobile,
      distributer_name,
    } = this.state;

    this.setState({ isSubmit: true, isLoading: true });

    var data = {
      id,
      rep_id,
      medicine_name,
      molecule,
      type,
      description,
      mobile,
      mrp,
      alt_distributer_name,
      alt_mobile,
      distributer_name,
    };

    axios
      .put(`${process.env.REACT_APP_API}/api/update-medicine`, data)
      .then((res) => {
        if (res.data.error) {
          this.setState({ isSubmit: false, isLoading: false });
          openNotification("danger", "Error", res.data.msg);
        } else {
          this.setState({ isSubmit: false, isLoading: false });
          openNotification("success", "Success", res.data.msg);
        }
      })
      .catch((err) => console.log(err));
  };

  getMedicineTypes = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/getMedicineTypesDetails`)
      .then((res) => {
        console.log("Result ==> ", res.data);
        if (res.data.error) {
          // this.setState({ isSubmit: false, isLoading: false });
          // openNotification("danger", "Error", res.data.msg);
        } else {
          // this.setState({ isSubmit: false, isLoading: false });
          // openNotification("success", "Success", res.data.msg);
          const data = res.data.data;
          this.setState({
            medicineTypes: data,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  onChange = (key, value) => {
    if (key === "type" && value === "0") value = "";
    this.setState({
      [key]: value,
    });
  };

  render() {
    return (
      <>
        {this.state.isLoading && <Spinner />}
        <div className="">
          <div className="breadcrumbs mt-0 customBreadcrumbs">
            <div className="breadcrumbs-inner">
              <div className="row m-0">
                <div className="col-sm-12 p-0">
                  <div className="page-header">
                    <div className="page-title">
                      <h1>Dashboard</h1>
                      <div className="page-title">
                        <ol className="breadcrumb text-right">
                          <li>
                            <a
                              href="!#"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.router.navigate("/medicine/list");
                              }}
                            >
                              Medicine
                            </a>
                          </li>
                          <li className="active">Update</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="animated fadeIn mt-3 commonLabel">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                {/* <div className="card-header">
                  <strong>Update Medicine</strong>
                  <small> Form</small>
                </div> */}
                <div className="card-header">
                  <h1>Update Medicine</h1>
                </div>
                <div className="card-body card-block text-left">
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="medicineName"
                          className="form-control-label required"
                        >
                          Medicine Name
                        </label>
                        <input
                          type="text"
                          id="medicineName"
                          placeholder="Enter the medicine name"
                          className="form-control"
                          value={this.state.medicine_name}
                          onChange={(e) =>
                            this.onChange("medicine_name", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="molecule"
                          className="form-control-label required"
                        >
                          Molecule
                        </label>
                        <input
                          type="text"
                          id="molecule"
                          placeholder="Enter the molecule name"
                          className="form-control"
                          value={this.state.molecule}
                          onChange={(e) =>
                            this.onChange("molecule", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="DistributerName"
                          className="form-control-label required"
                        >
                          Distributer Name
                        </label>
                        <input
                          type="text"
                          id="DistributerName"
                          placeholder="Enter the Distributer Name"
                          className="form-control"
                          value={this.state.distributer_name}
                          onChange={(e) =>
                            this.onChange("distributer_name", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="AltDistributerName"
                          className="form-control-label"
                        >
                          Alt Distributer Name
                        </label>
                        <input
                          type="text"
                          id="AltDistributerName"
                          placeholder="Enter the Alt Distributer Name"
                          className="form-control"
                          value={this.state.alt_distributer_name}
                          onChange={(e) =>
                            this.onChange(
                              "alt_distributer_name",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="mobileNo"
                          className="form-control-label required"
                        >
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          id="mobileNo"
                          placeholder="Enter the Mobile Number"
                          className="form-control"
                          value={this.state.mobile}
                          onChange={(e) =>
                            this.onChange("mobile", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="AltMobile"
                          className="form-control-label"
                        >
                          Alt Mobile Number
                        </label>
                        <input
                          type="text"
                          id="AltMobile"
                          placeholder="Enter the Alt Mobile Number"
                          className="form-control"
                          value={this.state.alt_mobile}
                          onChange={(e) =>
                            this.onChange("alt_mobile", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="type"
                          className=" form-control-label required"
                        >
                          Formulation
                        </label>
                        <select
                          id="type"
                          className="form-control"
                          value={this.state.type}
                          onChange={(e) =>
                            this.onChange("type", e.target.value)
                          }
                          required
                        >
                          <option value={0}>Please Select Type</option>
                          {this.state.medicineTypes &&
                            this.state.medicineTypes.length &&
                            this.state.medicineTypes.map((x, i) => (
                              <option key={i} value={x.product_name}>
                                {x.product_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="mrp"
                          className="form-control-label required"
                        >
                          MRP
                        </label>
                        <input
                          type="number"
                          id="mrp"
                          placeholder="Enter the MRP Price"
                          className="form-control txt-uppercase"
                          value={this.state.mrp}
                          onChange={(e) => this.onChange("mrp", e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="description"
                          className=" form-control-label"
                        >
                          Remark
                        </label>
                        <textarea
                          id="description"
                          placeholder="Enter the description"
                          className="form-control"
                          value={this.state.description}
                          onChange={(e) =>
                            this.onChange("description", e.target.value)
                          }
                          required
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="row form-group d-flex justify-content-center">
                    <input
                      className="btn btn-outline-success CusBtn"
                      type="submit"
                      value="Submit"
                      disabled={this.state.isSubmit}
                      onClick={() => this.onSubmitUpdateMedicine()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(MedicineUpdate);
