import { Store } from 'react-notifications-component';


export const getToken = () => {
    if (localStorage.token) {
        return localStorage.token;
    }
    return false;
};

export const openNotification = (type, title, message, msg = "Something went wrong") => {
    Store.addNotification({
        title,
        message,
        type,
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 3000,
            onScreen: true
        }
    });
};