import React from "react";
import { Navigate as Redirect, useLocation } from "react-router-dom";
import { getToken } from "./utils";
import DashBoard from "./Pages/Dashboard";

// const getToken = () => true;

const Auth = ({ children, props }) => {
  const location = useLocation();
  const type = localStorage.getItem("type");
  if (getToken()) {
    return type != "Admin" &&
      !["/medicine/register", "/medicine/list", "/medicine/update", "/medicine/upload"].includes(
        location.pathname
      ) ? (
      <Redirect
        to="/medicine/list"
        replace
        state={{ path: "/medicine/list" }}
      />
    ) : type == "Admin" &&
      ["/medicine/register"].includes(location.pathname) ? (
      <Redirect
        to="/medicine/list"
        replace
        state={{ path: "/medicine/list" }}
      />
    ) : (
      <DashBoard component={children} {...props}></DashBoard>
    );
  } else {
    return <Redirect to="/" replace state={{ path: location.pathname }} />;
  }
};

export default Auth;
