import React, { Component } from "react";
import axios from "axios";
import RichTextEditor from "react-rte";
import { openNotification } from "../../../utils";
import Spinner from "../../../Components/Spinner";
import withRouter from "../../withRouter";
import "./index.css";

class UpdateDoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      description: RichTextEditor.createValueFromString("", "html"),
      designation: "",
      qualification: "",
      image_file_path: "",
      address_line_1: "",
      address_line_2: "",
      area: "",
      pincode: "",
      isSubmit: false,
      isLoading: false,
      isView: true,
      imageFileName: "",
      image_pathStatus: "",
      base64: "",
    };
  }

  componentDidMount() {
    let route = this.props?.router?.location;
    if (Object.keys(route).length && route.state) {
      if (Object.keys(route?.state).length && !route?.state?.isView) {
        this.setState(
          { id: route?.state?.id, isView: route?.state?.isView },
          () => this.getDoctorView(true)
        );
      }
    }
  }

  getDoctorView = (noti = false) => {
    const { id } = this.state;

    this.setState({ isLoading: noti });
    axios
      .get(`${process.env.REACT_APP_API}/api/getDoctorDetails/${id}`)
      .then((res) => {
        if (res.data.error) {
          this.setState({ isLoading: false });
          openNotification("danger", "Error", res.data.msg);
        } else {
          let data = res.data.data;
          console.log(data);
          const RTEVal = RichTextEditor.createValueFromString(
            data.description,
            "html"
          );
          data.description = RTEVal;
          const base64 = data.image_file_path
            ? process.env.REACT_APP_FILE_URL + data.image_file_path
            : "";
          this.setState({ isLoading: false, ...data, base64 });
        }
      })
      .catch((err) => console.log(err));
  };

  onSubmitUpdateDoctor = () => {
    const {
      id,
      firstname,
      lastname,
      email,
      mobile,
      description,
      qualification,
      designation,
      image_file_path,
      address_line_1,
      address_line_2,
      area,
      pincode,
    } = this.state;

    this.setState({ isSubmit: true, isLoading: true });

    var data = {
      id,
      firstname,
      lastname,
      email,
      mobile,
      description: description.toString("html"),
      qualification,
      designation,
      image_file_path,
      address_line_1,
      address_line_2,
      area,
      pincode,
    };

    axios
      .put(`${process.env.REACT_APP_API}/api/update-doctor`, data)
      .then((res) => {
        // console.log("Result ==> ", res.data);
        if (res.data.error) {
          this.setState({ isSubmit: false, isLoading: false });
          openNotification("danger", "Error", res.data.msg);
        } else {
          this.setState({ isSubmit: false, isLoading: false });
          openNotification("success", "Success", res.data.msg);

          // this.setState(
          //   {
          //     firstname: "",
          //     lastname: "",
          //     description: "",
          //     designation: "",
          //     qualification: "",
          //     image_file_path: "",
          //     address_line_1: "",
          //     address_line_2: "",
          //     area: "",
          //     pincode: "",
          //     imageFileName: "",
          //     image_pathStatus: "",
          //   },
          //   () => {
          //     window.location.href = "/doctor/list";
          //   }
          // );
        }
      })
      .catch((err) => console.log(err));
  };

  getBase64 = (file, fileUploadStatus) => {
    return new Promise((resolve) => {
      this.setState({ [fileUploadStatus]: "start" });
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        setTimeout(() => {
          this.setState(
            { [fileUploadStatus]: "progress", base64: baseURL },
            () => setTimeout(() => resolve(baseURL), 600)
          );
        }, 600);
      };
    });
  };

  fileUploadSave = (file, keyName, fileName, fileStatus) => {
    let files = file[0];
    this.getBase64(files, fileStatus)
      .then((result) => {
        var data = { file: result };
        axios
          .post(`${process.env.REACT_APP_API}/api/file`, data)
          .then((res) => {
            if (res.data.error) {
              this.setState({ isSubmit: false, isLoading: false });
              openNotification("danger", "Error", res.data.msg);
            } else {
              this.setState({
                [fileName]: files.name,
                [keyName]: res.data.data.url,
                [fileStatus]: "success",
              });
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  renderFileUploadSwitch = (key) => {
    switch (key) {
      case "start":
        return <span>File Upload Started...</span>;
      case "progress":
        return <span className="fileStatus">File Uploading...</span>;
      case "success":
        return (
          <span className="fileStatusSuccess">File Uploaded Successfully</span>
        );
      default:
        break;
    }
  };

  onChangeRTEditor = (value) => {
    this.setState({ description: value });
  };

  render() {
    return (
      <>
        {this.state.isLoading && <Spinner />}
        <div className="">
          <div className="breadcrumbs mt-0 customBreadcrumbs">
            <div className="breadcrumbs-inner">
              <div className="row m-0">
                <div className="col-sm-12 p-0">
                  <div className="page-header">
                    <div className="page-title">
                      <h1>Dashboard</h1>
                      <div className="page-title">
                        <ol className="breadcrumb text-right">
                          <li>
                            <a
                              href="!#"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.router.navigate("/doctor/list");
                              }}
                            >
                              Doctor
                            </a>
                          </li>
                          <li className="active">Update Doctor</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="animated fadeIn mt-3 commonLabel">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                {/* <div className="card-header">
                  <strong>Update Doctor</strong>
                  <small> Form</small>
                </div> */}
                <div className="card-header">
                  <h1>Update Doctor</h1>
                </div>
                <div className="card-body card-block text-left">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <label
                          htmlFor="file-input"
                          className=" form-control-label"
                        >
                          File input
                        </label>
                        <div className="upload-options">
                          <div class="mx-auto d-block">
                            <img
                              className=" d-block"
                              src={this.state.base64}
                              alt="User Profile Image"
                              style={{
                                width: "150px",
                                height: "150px",
                              }}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "/assets/images/userPlaceholder.png";
                              }}
                            />
                          </div>
                          <div className="customFiles">
                            <label>
                              <i class="menu-icon fa fa-plus-square"></i>
                              <input
                                type="file"
                                id="file-input"
                                name="file-input"
                                className="form-control-file"
                                accept="image/*,.pdf,.docx"
                                onChange={(e) =>
                                  this.fileUploadSave(
                                    e.target.files,
                                    "image_file_path",
                                    "imageFileName",
                                    "image_pathStatus"
                                  )
                                }
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="fileStatusDiv">
                        {this.renderFileUploadSwitch(
                          this.state.image_pathStatus
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="firstname"
                          className="form-control-label required"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          id="firstname"
                          placeholder="Enter Your First Name"
                          className="form-control"
                          value={this.state.firstname}
                          onChange={(e) =>
                            this.onChange("firstname", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="lastname"
                          className="form-control-label"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="lastname"
                          placeholder="Enter Your First Name"
                          className="form-control"
                          value={this.state.lastname}
                          onChange={(e) =>
                            this.onChange("lastname", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="email" className=" form-control-label">
                          Email
                        </label>
                        <input
                          type="text"
                          id="email"
                          placeholder="Enter Your Email"
                          className="form-control"
                          value={this.state.email}
                          onChange={(e) =>
                            this.onChange("email", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="mobile"
                          className="form-control-label required"
                        >
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          id="mobile"
                          placeholder="Enter Your Mobile Number"
                          className="form-control"
                          value={this.state.mobile}
                          onChange={(e) =>
                            this.onChange("mobile", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="qualification"
                          className=" form-control-label"
                        >
                          Qualification
                        </label>
                        <input
                          type="text"
                          id="qualification"
                          placeholder="Enter Your Qualification"
                          className="form-control"
                          value={this.state.qualification}
                          onChange={(e) =>
                            this.onChange("qualification", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="designation"
                          className=" form-control-label required"
                        >
                          Designation
                        </label>
                        <input
                          type="text"
                          id="designation"
                          placeholder="Enter Your Designation"
                          className="form-control"
                          value={this.state.designation}
                          onChange={(e) =>
                            this.onChange("designation", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="address-line1"
                          className=" form-control-label required"
                        >
                          Address Line1
                        </label>
                        <input
                          type="text"
                          id="address-line1"
                          placeholder="Enter address line1"
                          className="form-control"
                          value={this.state.address_line_1}
                          onChange={(e) =>
                            this.onChange("address_line_1", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="address-line1"
                          className=" form-control-label"
                        >
                          Address Line2
                        </label>
                        <input
                          type="text"
                          id="address-line1"
                          placeholder="Enter address line2"
                          className="form-control"
                          value={this.state.address_line_2}
                          onChange={(e) =>
                            this.onChange("address_line_2", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="area" className=" form-control-label required">
                          Area
                        </label>
                        <input
                          type="text"
                          id="area"
                          placeholder="Enter Your Area"
                          className="form-control"
                          value={this.state.area}
                          onChange={(e) =>
                            this.onChange("area", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="pincode"
                          className=" form-control-label required"
                        >
                          Pincode
                        </label>
                        <input
                          type="text"
                          id="pincode"
                          placeholder="Enter Your Pincode"
                          className="form-control"
                          value={this.state.pincode}
                          onChange={(e) =>
                            this.onChange("pincode", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <RichTextEditor
                          className="react-rte-demo"
                          value={this.state.description}
                          onChange={this.onChangeRTEditor}
                          placeholder="Add a description"
                          toolbarClassName="demo-toolbar"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row form-group d-flex justify-content-center">
                    <input
                      className="btn btn-outline-success CusBtn"
                      type="submit"
                      value="Update"
                      disabled={this.state.isSubmit}
                      onClick={() => this.onSubmitUpdateDoctor()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(UpdateDoctor);
