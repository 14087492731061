import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { openNotification } from "../../utils";
import Spinner from "../../Components/Spinner";
import "./index.css";

function ResetPassword(props) {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setLoading] = useState(false);
  const [resetData, setResetData] = useState({
    email: "",
    confirm_password: "",
    password: "",
  });

  useEffect(() => {
    if (params && Object.values(params).length) {
      getForgotUser();
    } else {
      navigate("/");
    }
  }, []);

  const setFuncValue = (bool) => {
    setLoading(bool);
  };

  const getForgotUser = () => {
    const { id } = params;
    setFuncValue(true);
    axios
      .get(`${process.env.REACT_APP_API}/api/getResetPassword/${id}`)
      .then((res) => {
        if (res.data.error) {
          setFuncValue(false);
          openNotification("danger", "Error", res.data.msg);
          navigate("/");
        } else {
          let data = res.data.data;
          if (data && Object.keys(data).length && data.expires > 0) {
            openNotification("danger", "Error", res.data.msg);
            navigate("/");
          }
          setFuncValue(false);
          onChange("email", data.email);
        }
      })
      .catch((err) => console.log(err));
  };

  const resetPassword = () => {
    const { email, confirm_password, password } = resetData;
    if (password && password.length < 6) {
      openNotification(
        "danger",
        "Error",
        "Passwords must be at least 6-12 characters in length"
      );
      return;
    } else if (password && confirm_password && password !== confirm_password) {
      openNotification(
        "danger",
        "Error",
        "The password and confirm password doesn't match"
      );
      return;
    }
    setLoading(true);

    var data = { email, password };
    axios
      .post(`${process.env.REACT_APP_API}/api/resetPassword`, data)
      .then((res) => {
        if (res.data.error) {
          setFuncValue(false);
          openNotification("danger", "Error", res.data.msg);
        } else {
          setFuncValue(false);
          openNotification("success", "Success", res.data.msg);
          navigate("/");
          setResetData({
            email: "",
            password: "",
            confirm_password: "",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const onChange = (key, value) => {
    setResetData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="bg-dark content-fit">
        <div className="sufee-login d-flex align-content-center flex-wrap">
          <div className="container">
            <div className="login-content">
              <div className="logo-form mb-1">
                <div className="login-logo">
                  <a href="!#" onClick={(e) => e.preventDefault()}>
                    <img
                      className="align-content"
                      src="/assets/images/logo.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="login-form">
                <form>
                  <div className="form-group">
                    <label>Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      disabled
                      value={resetData.email}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Password"
                      onChange={(e) => onChange("password", e.target.value)}
                      required
                      value={resetData.password}
                    />
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Confirm Password"
                      onChange={(e) =>
                        onChange("confirm_password", e.target.value)
                      }
                      required
                      value={resetData.confirm_password}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-flat m-b-30 m-t-30"
                    onClick={(e) => {
                      e.preventDefault();
                      resetPassword();
                    }}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
