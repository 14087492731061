import React, { Component } from "react";
import axios from "axios";
import RichTextEditor from "react-rte";
import { openNotification } from "../../../utils";
import Spinner from "../../../Components/Spinner";
import withRouter from "../../withRouter";
import "./index.css";

class ViewDoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      designation: "",
      qualification: "",
      image_file_path: "",
      address_line_1: "",
      address_line_2: "",
      area: "",
      city: "",
      state: "",
      email: "",
      mobile: "",
      pincode: "",
      isLoading: false,
      description: RichTextEditor.createValueFromString("", "html"),
    };
  }

  componentDidMount() {
    let route = this.props?.router?.location;
    if (Object.keys(route).length && route.state) {
      if (Object.keys(route?.state).length && route?.state?.isView) {
        this.setState(
          { id: route?.state?.id, isView: route?.state?.isView },
          () => this.getDoctorView(true)
        );
      }
    }
  }

  getDoctorView = (noti = false) => {
    const { id } = this.state;

    this.setState({ isLoading: noti });
    axios
      .get(`${process.env.REACT_APP_API}/api/getDoctorDetails/${id}`)
      .then((res) => {
        if (res.data.error) {
          this.setState({ isLoading: false });
          openNotification("danger", "Error", res.data.msg);
        } else {
          let data = res.data.data;
          const RTEVal = RichTextEditor.createValueFromString(
            data.description,
            "html"
          );
          data.description = RTEVal;
          this.setState({ isLoading: false, ...data });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <>
        {this.state.isLoading && <Spinner />}

        <div className="">
          <div className="breadcrumbs mt-0 customBreadcrumbs">
            <div className="breadcrumbs-inner">
              <div className="row m-0">
                <div className="col-sm-12 p-0">
                  <div className="page-header">
                    <div className="page-title">
                      <h1>Dashboard</h1>
                      <div className="page-title">
                      <ol className="breadcrumb text-right">
                        <li>
                          <a
                            href="!#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.router.navigate("/doctor/list");
                            }}
                          >
                            Doctor
                          </a>
                        </li>
                        <li className="active">View Doctor</li>
                      </ol>
                    </div>
                    </div>
                  </div>
                </div>
            
              </div>
            </div>
          </div>
        </div>

        <div className="animated fadeIn mt-3 commonLabel">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h1>View Doctor</h1>
                </div>
                <div className="card-body card-block text-left">
                  <div className="row mb-2">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        {/* <label htmlFor="file" className=" form-control-label">
                          Input File
                        </label> */}
                        <div class="mx-auto d-block">
                          <img
                            className="rounded-circle"
                            src={`${process.env.REACT_APP_FILE_URL}${this.state.image_file_path}`}
                            alt="User Profile Image"
                            style={{
                              width: "125px",
                              height: "125px",
                              margin:'auto'
                            }}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src =
                                "/assets/images/userPlaceholder.png";
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="firstname"
                          className="form-control-label"
                        >
                          First Name
                        </label>
                        <h5 className="form-control-static">
                          {this.state.firstname}
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="lastname"
                          className="form-control-label"
                        >
                          Last Name
                        </label>
                        <h5 className="form-control-static">
                          {this.state.lastname}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="email" className="form-control-label">
                          Email
                        </label>
                        <h5 className="form-control-static">
                          {this.state.email}
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="mobile" className="form-control-label">
                          Mobile
                        </label>
                        <h5 className="form-control-static">
                          {this.state.mobile}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="qualification"
                          className=" form-control-label"
                        >
                          Qualification
                        </label>
                        <h5 className="form-control-static">
                          {this.state.qualification}
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="designation"
                          className=" form-control-label"
                        >
                          Designation
                        </label>
                        <h5 className="form-control-static">
                          {this.state.designation}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="address-line1"
                          className=" form-control-label"
                        >
                          Address Line1
                        </label>
                        <h5 className="form-control-static">
                          {this.state.address_line_1}
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="address-line1"
                          className=" form-control-label"
                        >
                          Address Line2
                        </label>
                        <h5 className="form-control-static">
                          {this.state.address_line_2}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="area" className=" form-control-label">
                          Area
                        </label>
                        <h5 className="form-control-static">
                          {this.state.area}
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="city" className=" form-control-label">
                          City
                        </label>
                        <h5 className="form-control-static">
                          {this.state.city}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="state" className=" form-control-label">
                          State
                        </label>
                        <h5 className="form-control-static">
                          {this.state.state}
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="pincode"
                          className=" form-control-label"
                        >
                          Pincode
                        </label>
                        <h5 className="form-control-static">
                          {this.state.pincode}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <RichTextEditor
                          className="react-rte-demo"
                          value={this.state.description}
                          readOnly={true}
                          placeholder="Add a description"
                          toolbarClassName="demo-toolbar"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ViewDoctor);
