import React, { PureComponent } from 'react';
import withRouter from '../withRouter';

class NotFound extends PureComponent {
    goHome = () => {
        this.props.router.navigate('/doctor/list');
    };

    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <img src={`/assets/images/404assest.png`} style={{ marginTop: '10rem' }} alt="404" />
                    <h5 style={{ marginTop: '2rem' }}>The page you are looking for doesn't exist or an error occurred.</h5>
                    <h5>
                        <span>Go Back</span> to <span onClick={this.goHome} className="linksIn404">Home</span>
                    </h5>
                </div>
            </div>
        );
    }
};

export default withRouter(NotFound);