import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { openNotification } from "../../utils";
import Spinner from "../../Components/Spinner";
import {  Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import "./index.css";

function Login(props) {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    otp: "",
  });

  const setFuncValue = (bool) => {
    setLoading(bool);
  };

  const login = () => {
    const { email, password, otp } = loginData;
    let re = /\S+@\S+\.\S+/;
    if (email.trim() === "" || !re.test(email)) {
      openNotification("danger", "Error", "Please Enter a valid email address");
      return;
    }
    setLoading(true);
    var data = { email, password, otp, type: !isAdmin ? "rep" : "admin" };
    axios
      .post(`${process.env.REACT_APP_API}/api/login`, data)
      .then((res) => {
        if (res.data.error) {
          setFuncValue(false);
          openNotification("danger", "Error", res.data.msg);
        } else {
          setFuncValue(false);
          openNotification("success", "Success", res.data.msg);
          localStorage.setItem("isLogin", "true");
          localStorage.setItem("token", res.data.data.accessToken);
          localStorage.setItem("type", res.data.data.type);
          if (isAdmin) {
            navigate("/doctor/list");
          } else {
            navigate("/medicine/list");
          }

          setLoginData({
            email: "",
            password: "",
            otp: "",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const getOTP = () => {
    const { email } = loginData;
    let re = /\S+@\S+\.\S+/;
    if (email.trim() === "" || !re.test(email)) {
      openNotification("danger", "Error", "Please Enter a valid email address");
      return;
    }
    setLoading(true);
    var data = { email, type: !isAdmin ? "rep" : "admin" };
    axios
      .post(`${process.env.REACT_APP_API}/api/sendOTP`, data)
      .then((res) => {
        if (res.data.error) {
          setFuncValue(false);
          openNotification("danger", "Error", res.data.msg);
        } else {
          setFuncValue(false);
          openNotification("success", "Success", res.data.msg);
        }
      })
      .catch((err) => console.log(err));
  };

  const onChange = (key, value) => {
    setLoginData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const GetBtnComp = () => {
    return (
      <button
        type="submit"
        className="btn btn-success btn-flat m-b-30 m-t-30"
        onClick={(e) => {
          e.preventDefault();
          login();
        }}
      >
        Sign in
      </button>
    );
  };

  const setCheckbox = (e, type = "otp") => {
    const { checked } = e.target;
    if (type == "otp") {
      setIsOTP(checked);
    } else {
      setIsAdmin(checked);
    }
  };

  const GetPassOtpComp = (key = "pass") => {
    return (
      <div className="form-group mb-10">
        <label>{key == "pass" ? "Password" : "OTP"}</label>
        <input
          type={key == "pass" ? "password" : "text"}
          className="form-control"
          placeholder={key == "pass" ? "Password" : "OTP"}
          onChange={(e) =>
            onChange(`${key == "pass" ? "password" : "otp"}`, e.target.value)
          }
          required
          value={key == "pass" ? loginData.password : loginData.otp}
        />
      </div>
    );
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="content-fit">
        <div className="sufee-login d-flex align-content-center flex-wrap">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                {/* <div className="fxt-bg-img">
                  
                </div> */}
                <Swiper
                  className="loginSwipers"
                  modules={[Pagination]}
                  spaceBetween={50}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide>
                    <div className="fxt-bg-imgs">
                      <img
                        className="align-content"
                        src="/assets/images/slider.png"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="fxt-bg-imgs">
                      <img
                        className="align-content"
                        src="/assets/images/slider.png"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="fxt-bg-imgs">
                      <img
                        className="align-content"
                        src="/assets/images/slider.png"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="col-md-6">
                <div className="login-content">
                  <div className="login-form box-shadow">
                    <div className="login-logo mb-20">
                      <a
                        href="!#"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <img
                          className="align-content"
                          src="/assets/images/logo.png"
                          alt=""
                        />
                      </a>
                    </div>
                    <form>
                      <div className="form-group">
                        <label>Email address</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          onChange={(e) => onChange("email", e.target.value)}
                          required
                          value={loginData.email}
                        />
                      </div>
                      {isAdmin && GetPassOtpComp("pass")}
                      {!isAdmin && isOTP && GetPassOtpComp("otp")}
                      <div className="checkbox">
                        <label for="defaultCheck1">
                          <input
                            type="checkbox"
                            checked={isAdmin}
                            onChange={(e) => {
                              setCheckbox(e, "admin");
                              setIsOTP(false);
                            }}
                            id="defaultCheck1"
                          />{" "}
                          Are you an admin ?
                        </label>
                      </div>
                      <div className="checkbox">
                        {/* <label>
                      <input type="checkbox" /> Remember Me
                    </label> */}
                        {isAdmin && (
                          <label className="pull-right">
                            <a
                              href="!#"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/forgot");
                              }}
                            >
                              Forgot password?
                            </a>
                          </label>
                        )}
                        {isOTP && (
                          <label className="pull-right">
                            <a
                              href="!#"
                              onClick={(e) => {
                                e.preventDefault();
                                getOTP();
                              }}
                            >
                              Resend OTP {}
                            </a>
                          </label>
                        )}
                      </div>
                      {isAdmin && !isOTP && <GetBtnComp />}
                      {isOTP && <GetBtnComp />}
                      {!isAdmin && !isOTP && (
                        <button
                          type="submit"
                          className="btn btn-success btn-flat m-b-30 m-t-30"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsOTP(true);
                            getOTP();
                          }}
                          disabled={!loginData.email.length}
                        >
                          Get OTP
                        </button>
                      )}
                      <div className="register-link m-t-15 text-center">
                        <p></p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
