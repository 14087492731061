import React, { Component } from "react";
import axios from "axios";
import { openNotification } from "./utils";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./Pages/Notfound";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ForgotPassword/resetPassword";
import Auth from "./Auth";
import List from "./Pages/Doctor/List";
import RegisterDoctor from "./Pages/Doctor/Register";
import ViewDoctor from "./Pages/Doctor/View";
import UpdateDoctor from "./Pages/Doctor/Update";
import RepresentativeList from "./Pages/Representative/List";
import RepresentativeRegister from "./Pages/Representative/Register";
import RepresentativeUpdate from "./Pages/Representative/Update";
import RepresentativeUpload from "./Pages/Representative/Upload";
import MedicineList from "./Pages/Medicine/List";
import MedicineRegister from "./Pages/Medicine/Register";
import MedicineUpdate from "./Pages/Medicine/Update";
import MedicineUpload from "./Pages/Medicine/Upload";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // Do something before request is sent
    axios.interceptors.request.use(function (config) {
      let token = localStorage.getItem("token");
      if (token) {
        config.headers.authorization = token;
      }
      return config;
    });

    // Do something with response data
    axios.interceptors.response.use(
      function (res) {
        if (res.data.error) {
          if (res.data.error && res.data.status === 401) {
            openNotification("danger", "Error", res.data.msg);
            setTimeout(() => (window.location.href = "/"), 1000);
            return;
          } else {
            return res;
          }
        } else {
          return res;
        }
      },
      function (error) {
        // Do something with response error
        console.error(error);
        return Promise.reject(error);
      }
    );
  }

  render() {
    return (
      <main>
        <Router>
          <Routes>
            <Route path="/" element={<Login {...this.props} />} />
            <Route
              path="/forgot"
              element={<ForgotPassword {...this.props} />}
            />
            <Route
              path="/reset/:id"
              element={<ResetPassword {...this.props} />}
            />
            <Route
              path="/dashboard"
              element={<Auth props={this.props} children={Dashboard} />}
            />
            <Route
              path="/doctor/list"
              element={<Auth props={this.props} children={List} />}
            />
            <Route
              path="/doctor/view"
              element={<Auth props={this.props} children={ViewDoctor} />}
            />
            <Route
              path="/doctor/register"
              element={<Auth props={this.props} children={RegisterDoctor} />}
            />
            <Route
              path="/doctor/update"
              element={<Auth props={this.props} children={UpdateDoctor} />}
            />
            <Route
              path="/representative/list"
              element={
                <Auth props={this.props} children={RepresentativeList} />
              }
            />
            <Route
              path="/representative/register"
              element={
                <Auth props={this.props} children={RepresentativeRegister} />
              }
            />
            <Route
              path="/representative/update"
              element={
                <Auth props={this.props} children={RepresentativeUpdate} />
              }
            />
            <Route
              path="/representative/upload"
              element={
                <Auth props={this.props} children={RepresentativeUpload} />
              }
            />
            <Route
              path="/medicine/list"
              element={<Auth props={this.props} children={MedicineList} />}
            />
            <Route
              path="/medicine/register"
              element={<Auth props={this.props} children={MedicineRegister} />}
            />
            <Route
              path="/medicine/update"
              element={<Auth props={this.props} children={MedicineUpdate} />}
            />
            <Route
              path="/medicine/upload"
              element={<Auth props={this.props} children={MedicineUpload} />}
            />
            {/*<Route path="/individual/list" element={
                            <Auth props={this.props} children={IndividualContactList} />
                        } />
                         */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </main>
    );
  }
}

export default Main;
